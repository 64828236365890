<template>
  <div :style="{
        'background-image': `url(${require('../assets/bridge_2.jpg')})`,
        'height': screenHeight,
        'width': screenWidth,
        'background-size': 'cover',
        'background-position': 'right center',
        'transform': 'scale(1.0)',
      }" class="center">
    <!-- <q-input
      outlined
      v-model="searchText"
      label="Enter an address, city or ZIP code"
      bg-color="white"
      @keyup.enter="handleEnter"
    > -->
      <!-- <template v-slot:append> -->
        <!-- <q-avatar> -->
          <!-- <q-icon name="search" size="1.7em"/> -->
        <!-- </q-avatar> -->
      <!-- </template> -->
    <!-- </q-input> -->

    <q-btn @click="handleEnter" color="white" text-color="black" label="New Listings - Click Here" style="
        z-index: 949;
      "/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import router from "../router"
export default {
  name: "Search",
  setup() {
    const searchText = ref("")
    function handleEnter() {
      // console.log(searchText.value)
      router.push({name: "listings"})
    }

    const screenHeight = window.screen.height + "px";
    const screenWidth = window.screen.width + "px";
    

    return {
      handleEnter,
      searchText,
      screenHeight,
      screenWidth
    }
  }
};
</script>

<style>

 .center {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 200px;
  border: 3px solid green;  */
}
</style>