<template>
  <footer style="background-color: #205d9b">
    <div
      style="
        padding: 35px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
      "
    >
      <div>
        <q-avatar size="150px" font-size="82px" square>
          <q-img :src="require('../assets/lisa_footer.jpg')" /> </q-avatar
        >
        <q-img style="margin-top: 10px" :src="require('../assets/footer_logo.png')" />
        <div class="footer-text footer-name">
          <strong>Lisa L. Walker</strong>
        </div>
        <div class="footer-text">
          Texas REALTOR®, BROKER, ABR®, RENE, TAHS, PSA, GRI<br />
          Accredited Buyers Representative<br />
          Real Estate Negotiations Expert<br />
          Texas Affordable Housing Specialist<br />
          Pricing Strategy Advisor<br />
          Graduate Realtor Institue<br />
        </div>
      </div>
      <!-- DIV FOR SPACE IN BETWEEN -->
      <div class="empty-div"></div>
      <div
        class="footer-text"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div class="referral-div">
          <i>I'm never too busy for referrals to your friends & family!</i>
        </div>
        <div class="referral-div">
          Cell: 817-996-6889<br />
          <a href="mailto:homesbylw@gmail.com">homesbylw@gmail.com</a><br />
          <a href="www.lisaw.net">www.lisaw.net</a> <br />
          <a href="www.hiddenjewelhomes.net">www.hiddenjewelhomes.net</a> <br />
          Broker Associate with<br />
          Texcel Real Estate, LLC<br />
        </div>
        <div style="color: white" class="referral-div">
          Texas law requires all Texas License Holders to provide the following
          information:<br />
          <a
            href="https://www.dropbox.com/s/vhiep8qgzu8dtm6/2022%20IABS%20Walker%20Texcel.pdf?dl=0"
            target="__blank"
            >Texas Real Estate Commission Information About Brokerage
            Services</a
          ><br />
          <a
            href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-3.pdf"
            >Consumer Protection Notice</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  setup() {},
};
</script>

<style scoped>
a {
  color: white;
}
p {
  color: white;
}
.footer-text {
  color: white;
}

.footer-name {
  font-size: 10vw;
}

.empty-div {
  width: 20%;
}

@media screen and (min-width: 700px) {
  .footer-name {
    font-size: 1.5vw;
  }
  .empty-div {
    width: 10%;
  }

  .referral-div {
    margin-bottom: 5.5vh;
  }
}
</style>
