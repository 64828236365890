<template>
  <div style="background-color: #205d9b; height: 100vh">
    <div>
      <q-img
        :src="require('../assets/bridge_2.jpg')"
        :fit="none"
        :img-style="{ filter: 'brightness(90%)' }"
        height="15vh"
      >
      </q-img>
      <Header class="header" bgTransparent textColor="white" />
    </div>
    <div
      style="
        background-color: #205d9b;
        height: auto;
        min-height: 100% !important;
        z-index: 49;
      "
    >
      <div
        style="
          height: 100%;
          width: 70vw;
          margin: auto;
          z-index: 50;
          background-color: white;
          margin-top: 10vh;
          box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.25),
            0 16px 30px 0 rgba(0, 0, 0, 0.4);
          padding: 1px 10px 5px 15px;
          display: flex;
          flex-direction: column;
        "
      >
        <h5>A little about me, but you are my priority!</h5>
        <p>
          I’m a native Texan and been in the DFW area my whole life. I have been
          married for 31 years to my best friend, John. We’ve raised our 3
          children in Fort Worth and East Parker County and we are newly empty
          nesters. Our oldest son has multiple disabilities and raising him was
          very challenging but rewarding. He taught me how to be a strong
          advocate. Our youngest daughter was diagnosed with Leukemia at 10
          years old and after 3 years of chemo, she is a thriving survivor. My
          first career was as a hairstylist working in downtown Fort Worth for
          many years. My work experiences with people and the challenges I have
          faced with my family has shaped me tremendously. I’m resourceful,
          responsible and very tenacious. I advocate strongly for my clients
          like they’re family, after all they are my real estate family!
        </p>
        <p>
          If you want a REALTOR that believes in educating her clients, works
          full time, will really listens to your wants and needs, develops you a
          plan, is a strong negotiator for you, refers you to other
          professionals if needed, and will gently guide and educate you through
          the process– then I’m the REALTOR you’ve been looking for.
        </p>
        <p>
          My goal is to exceed your expectations while making your experience
          easy and enjoyable. Call me at 817.996.6889 and let’s get started
          today!
        </p>
        <p>
          <strong
            ><i><u>My Special Designations:</u></i></strong
          ><br />
          ABR- Accredited Buyers Representative<br />
          RENE- Real Estate Negotiation Expert<br />
          PSA- Pricing Strategy Advisor
        </p>
        <p>
          <strong
            ><i><u>My Certifications:</u></i></strong
          ><br />
          TAHS- Texas Affordable Homes Specialist<br />
          SFR- Short Sales and Foreclosure Resource<br />
          TCA- Technology Certified Agent<br />
          GRI- Graduate REALTOR Institute
        </p>
        <p>
          <strong
            ><i><u>Proud Member of:</u></i></strong
          ><br />
          Greater Ft Worth Board of Realtors<br />
          Texas Association of Realtors<br />
          National Association of Realtors<br />
          Real Estate Buyer Agent Council
        </p>

        <p>
          <strong>Now, tell me about you:</strong>
        </p>
        <p>
          <strong>What is your dream? </strong><br />This is a great question
          because it’s what we are all working to fulfill. We are striving hard
          to fulfill our dreams, including those of our family. We want to make
          their dreams happen too! I would like an opportunity to be your
          Realtor and help you in this endeavor. Your home is more than its
          physical features, it’s the place where you and your family nurture
          your relationships and build dreams. So, this is more than real
          estate; it’s about your dreams. Let’s work on your home ownership
          dreams together and let me help you make them a reality!
        </p>
      </div>
    </div>
    <Footer :style="{'padding-top': '3%'}" />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  name: "About",
  components: {
    Header,
    Footer,
  },
  setup() {
    const screenHeight = window.screen.height + "px";
    return {
      screenHeight,
      Header,
      Footer,
    };
  },
};
</script>

<style>
</style>