import * as VueRouter from "vue-router";
import LandingPage from "../components/LandingPage.vue";
import About from "../components/About.vue";
import IDXComponent from "../components/IDXComponent.vue"
import Listings from "../components/Listings.vue"
import Buy from "../components/Buy.vue";
import Sell from "../components/Sell.vue";


const routes = [
    { path: '/', component: LandingPage, name: "Index", props: true },
    { path: '/about', component: About, props: true },
    { path: '/search', component: IDXComponent, name: "search", props: true },
    { path: '/listings', component: Listings, name: "listings" },
    { path: '/buy', component: Buy, name: "Buy"},
    { path: '/sell', component: Sell, name: "Sell"},
]

const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHashHistory(),
    routes, // short for `routes: routes`
})

export default router;