<template>
  <div :style="{ backgroundColor: colorBg }">
    <q-toolbar :class="headerClass">
      <!-- <q-avatar>
        <img :src="require('../assets/logo.jpg')">
      </q-avatar> -->
      <q-btn no-caps :ripple="false" @click="pushToHome" flat size="4.0vw" class="biggerHeading">
        <span><strong>Lisa Walker</strong></span>
      </q-btn>
      <!-- HEADING FOR SMALLER DEVICES -->
      <q-btn no-caps :ripple="false" @click="pushToHome" flat size="8.0vw" class="response_menu">
        <span><strong>Lisa Walker</strong></span>
      </q-btn>
      <q-space />
      <div class="headerContainer">
        <q-btn no-caps label="Home" @click="pushToHome" flat size="1.75vw" />
        <q-btn
          no-caps
          label="MLS Search"
          @click="pushToSearch"
          flat
          size="1.5vw"
        />
        <q-btn no-caps label="About" @click="pushToAbout" flat size="1.75vw" />
        <q-btn no-caps label="Buy" @click="pushToBuy" flat size="1.75vw" />
        <q-btn no-caps label="Sell" @click="pushToSell" flat size="1.75vw" />
      </div>
      <q-btn
        flat
        round
        dense
        icon="menu"
        @click="toggleMenu"
        class="response_menu"
        size="5.0vw"
      />
      <!-- <q-btn no-caps label="Contact" flat size="2.5vh" /> -->
    </q-toolbar>
    <div class="collapsedHeaderContainer" id="collapsedHeaderContainer">
      <q-btn no-caps label="Home" @click="pushToHome" flat size="4.0vw" />
      <q-btn
        no-caps
        label="MLS Search"
        @click="pushToSearch"
        flat
        size="4.0vw"
      />
      <q-btn no-caps label="About" @click="pushToAbout" flat size="4.0vw" />
      <q-btn no-caps label="Buy" @click="pushToBuy" flat size="4.0vw" />
      <q-btn no-caps label="Sell" @click="pushToSell" flat size="4.0vw" />
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import router from "../router";
export default {
  name: "Header",
  props: {
    bgTransparent: Boolean,
    textColor: String,
    bgColor: String,
  },
  setup(props) {
    const { bgTransparent, textColor, bgColor } = toRefs(props);
    let headerBg = "";
    let headerText = "";
    const colorBg = bgColor.value;

    if (bgTransparent.value) {
      headerBg = "bg-transparent";
    }

    if (textColor.value === "white") {
      headerText = "text-white";
    }
    const headerClass = `${headerBg} q-my-md ${headerText} `;
    function pushToAbout() {
      router.push("/about");
    }

    function pushToHome() {
      router.push("/");
    }

    function pushToSearch() {
      router.push("/search");
    }

    function pushToBuy() {
      router.push("/buy");
    }

    function pushToSell() {
      router.push("/sell");
    }

    function toggleMenu() {
      const element = document.querySelector("#collapsedHeaderContainer");
      element.classList.toggle("collapsedHeaderContainer");
    }

    return {
      pushToAbout,
      pushToHome,
      headerClass,
      colorBg,
      pushToSearch,
      pushToBuy,
      pushToSell,
      toggleMenu,
    };
  },
};
</script>

<style>
#collapsedHeaderContainer {
  display: flex;
  flex-direction: column;
  color: white;
}
.collapsedHeaderContainer {
  visibility: hidden;
}
@media screen and (max-width: 700px) {
  .response_menu {
    display: block;
  }

  .headerContainer {
    visibility: hidden;
  }

  .biggerHeading {
    display: none;
  }
}
@media screen and (min-width: 701px) {
  .response_menu {
    display: none;
  }
}
</style>