<template>
  <div>
    <!-- <Header class="" bgColor="black" textColor="white" /> -->
    <iframe
      src="https://ntrdd.mlsmatrix.com/Matrix/public/IDX.aspx?idx=f7973a5e"
      width="100%"
      :height="screenHeight"
      id="main_iframe"
    ></iframe>
    <Footer />
  </div>
</template>
<script>
// import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  name: "IDXComponent",
  components: {
    Footer,
    // Header,
  },
  props: {
    test: String,
  },
  setup() {
    const screenHeight = window.screen.height + "px";
    return {
      screenHeight,
    };
  },
};
</script>
<style>
</style>