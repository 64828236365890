<template>
  <div>
    <Header class="header" bgTransparent textColor="white" />
    <!-- <HomePage /> -->
    <Search class='z-top'/>
    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue";
// import HomePage from "./HomePage.vue";
import Search from "./Search.vue";
import Footer from "./Footer.vue"
export default {
  name: "LandingPage",
  components: {
    // HomePage,
    Header,
    Search,
    Footer
  },
};
</script>

<style>
</style>