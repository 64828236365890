<template>
  <div>
    <Header class="header" bgTransparent textColor="white" />
    <q-img
      :src="require('../assets/underConstruction.jpg')"
      :fit="none"
      :img-style="{ filter: 'brightness(90%)' }"
      height="100vh"
    ></q-img>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue"
export default {
  name: "Sell",
  components: {
    Header,
    Footer
  },
  setup() {
    return {
      Header,
      Footer
    };
  },
};
</script>
