<template>
  <div class="container">
    <router-view style="width: 100%" />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* @media screen and (min-width: 720px) {
  .container {
    position: relative;
  }
}

@media screen and (max-width: 720px) {
  html {
    overflow: auto;
  }


  #app {
    width: 710px;
  }
} */
.header {
  position: absolute;
  top: 0;
  height: 20vh;
  width: 100vw;
  z-index: 99;
  /* font-size: 18vw; */
}

/* .search-bar {
  position: absolute;
  top: 35%;
  margin-left: 42.5%;
  z-index: 100;
  width: 45%;
} */
</style>
