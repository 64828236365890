<template>
  <div style="background-color: #205d9b">
    <div>
      <q-img
        :src="require('../assets/bridge_2.jpg')"
        :fit="none"
        :img-style="{ filter: 'brightness(90%)' }"
        height="15vh"
      >
      </q-img>
      <Header class="header" bgTransparent textColor="white" />
    </div>

    <div class="q-pa-lg row items-start q-gutter-sm">

      <q-card class="my-card">
        <q-img
          src="https://images.homes.com/listings/102/6617772183-215123571/1616-s-lake-rd-holliday-tx-buildingphoto-4.jpg"
        >
          <div class="absolute-bottom">
            <div class="text-h6">1616 S Lake Rd</div>
            <div class="text-subtitle2">$85,000</div>
          </div>
        </q-img>

        <q-card-actions>
          <q-btn flat href="https://www.homes.com/property/1616-s-lake-rd-holliday-tx/ghk3mq7hcnxel/">View Listing</q-btn>
        </q-card-actions>
      </q-card>
    </div>
  </div>
  <Footer :style="{ 'padding-top': '3%' }" />
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  name: "About",
  components: {
    Header,
    Footer,
  },
  setup() {
    const screenHeight = window.screen.height + "px";
    return {
      screenHeight,
      Header,
      Footer,
    };
  },
};
</script>

<style scoped>
.my-card {
  width: 800px;
  max-width: 450px;
  /* margin: 1vh auto; */
  /* height: 10vh; */
}
</style>